import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const StyledTemplates = styled.section`
    margin: 20px;

    .templates {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        .template {
            width: 300px;
            padding-right: 20px;

            h3 {
                text-align: center;
                color: ${props => props.theme.colors.pink}
            }

            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
            }

            a {
                display: block;
                margin: 0 auto;
                border-radius: 20px;
                padding: 15px;
                background: linear-gradient(to right,rgb(243, 117, 117),rgb(255, 119, 175));
                font-weight: bold;
                color: white;
                font-size: 1.2rem;
                padding: 10px;
                margin-top: 20px;
                text-align: center;
                text-decoration: none;
                border: 1px solid transparent;
                position: relative;

                &:hover {
                    background: linear-gradient(to right, white, white);
                    border: 1px solid ${props => props.theme.colors.pink};
                    color: ${props => props.theme.colors.pink};
                }

                i {
                    position: absolute;
                    right: 13px;
                    top: 50%;
                    margin-top: -10px;
                }
            }
        }
    }
`;

const Templates = () => (
    <StyledTemplates>
        <Helmet>
            <title>Pub golf pre made templates, 9 holes, 18 holes - Pub Golf Scorecard</title>
        </Helmet>
        <h2>Pub Golf Templates</h2>
        <p>If you would prefer a scorecard template rather than filling in your pub golf scorecard online, we have provided some for you below to download.</p>
        <div className="templates">
            <div className="template">
                <h3>Blank scorecard 9 holes</h3>
                <p>This is a blank scorecard for 9 holes of pub golf that you can print and fill in yourself after printing.</p>
                <img src='/static/blank_scorecard_9.jpg' alt='Blank 9 hole pub golf scorecard' />
                <a href="/static/scorecard_blank_9.pdf" target="_blank">Download this PDF<i className="fas fa-external-link-alt"></i></a>
            </div>
            <div className="template">
                <h3>Blank scorecard 18 holes</h3>
                <p>This is a blank scorecard for 18 holes of pub golf that you can print and fill in yourself after printing.</p>
                <img src='/static/blank_scorecard_9.jpg' alt='Blank 18 hole pub golf scorecard' />
                <a href="/static/scorecard_blank_18.pdf" target="_blank">Download this PDF<i className="fas fa-external-link-alt"></i></a>
            </div>
            <div className="template">
                <h3>Filled scorecard 9 holes</h3>
                <p>This is a pre filled in scorecard for 9 holes of pub golf that you can print and use, simples!.</p>
                <img src='/static/blank_scorecard_9.jpg' alt='Filled in 9 hole pub golf scorecard, ready to use!' />
                <a href="/static/scorecard_filled_9.pdf" target="_blank">Download this PDF<i className="fas fa-external-link-alt"></i></a>
            </div>
        </div>
    </StyledTemplates>
);

export default Templates;