import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

const StyledPubs = styled.section`
    .no-items {
        text-align: center;
        margin-top: 20px;
        font-size: 1.4rem;
        color: ${props => props.theme.colors.pink};

        .mobile-message {
            display: block;
            font-size: 1rem;
            background-color: white;
            padding: 10px;
            border-radius: 10px;

            @media ${props => props.theme.breakpoints.medium} {
                display: none;
            }
        }

        .desktop-message {
            display: none;
            background-color: white;
            padding: 10px;
            border-radius: 10px;

            @media ${props => props.theme.breakpoints.medium} {
                display: block;
            }
        }
    }
`;

class Pubs extends React.Component {
    constructor() {
        super();

        this.state = {
            editFieldPub: null,
            editFieldDrink: null,
            editFieldPar: null
        }
    }

    editFieldPubName = (index) => {
        this.setState({ editFieldPub: index });
        setTimeout(function(){ document.getElementById(`pub${index}`).focus(); }, 50);
    }

    editFieldDrink = (index) => {
        this.setState({ editFieldDrink: index });
        setTimeout(function(){ document.getElementById(`drink${index}`).focus(); }, 50);
    }

    editFieldPar = (index) => {
        this.setState({ editFieldPar: index });
        setTimeout(function(){ document.getElementById(`par${index}`).focus(); }, 50);
    }

    render() {
        const { pubs, addPub, deletePub, editPub, editDrink, editPar } = this.props;
        const { editFieldPub, editFieldDrink, editFieldPar } = this.state;

        return (
            <StyledPubs>
                <div className="forms">
                    <h2>2. Enter the pubs and drinks on your course</h2>
                    <div className="info">
                    <div className="enter-info">
                        <form onSubmit={(e) => addPub(e)}>
                            <label htmlFor="pub" className="visuallyhidden">Pub name</label>
                            <input type="text" id="pub" name="pub" required placeholder="Enter a pub..." />
                            <label htmlFor="drink" className="visuallyhidden">Drink</label>
                            <input type="text" id="drink" name="drink" required placeholder="Enter a drink..." />
                            <label htmlFor="par" className="visuallyhidden">Par</label>
                            <select id="par" name="par" required>
                                <option value="">Choose a par</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                            <button type="submit">Add Pub<i className="fas fa-plus"></i></button>
                        </form>
                    </div>
                    <div className="list">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="hole-header">
                                            Hole
                                        </th>
                                        <th>
                                            Pub
                                        </th>
                                        <th>
                                            Drink
                                        </th>
                                        <th className="par-header">
                                            Par
                                        </th>
                                        <th className="small-row"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                pubs && pubs.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="hole-row">
                                            {index + 1}
                                        </td>
                                        <td>
                                            <div onClick={() => this.editFieldPubName(index)} className={editFieldPub === index ? 'hidden' : 'edit-rule'}>{item.pub}</div>
                                            <input id={`pub${index}`} className={editFieldPub === index ? '' : 'hidden'} value={item.pub} onChange={(e) => editPub(e, index)} onBlur={() => this.setState({ editFieldPub: null })} />
                                        </td>
                                        <td>
                                            <div onClick={() => this.editFieldDrink(index)} className={editFieldDrink === index ? 'hidden' : 'edit-rule'}>{item.drink}</div>
                                            <input id={`drink${index}`} className={editFieldDrink === index ? '' : 'hidden'} value={item.drink} onChange={(e) => editDrink(e, index)} onBlur={() => this.setState({ editFieldDrink: null })} />
                                        </td>
                                        <td className="par-row">
                                            <div onClick={() => this.editFieldPar(index)} className={editFieldPar === index ? 'hidden' : 'edit-rule'}>{item.par}</div>
                                            <select id={`par${index}`} className={editFieldPar === index ? '' : 'hidden'} value={item.par} onChange={(e) => editPar(e, index)} onBlur={() => this.setState({ editFieldPar: null })}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </td>
                                        <td className="small-row">
                                            <i className="far fa-trash-alt delete" onClick={() => deletePub(index)}></i>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                            </table>
                            {
                                pubs.length === 0 ? <div className="no-items">
                                    <div className="desktop-message"><i className="far fa-hand-point-left"></i> <b>Start by adding a pub using the form to the left</b></div>
                                    <div className="mobile-message"><i className="far fa-hand-point-up"></i> <b>Start by adding a pub using the form above</b></div>
                                </div> : <React.Fragment />
                            }
                            
                    </div>
                    </div>
                </div>
            </StyledPubs>
        )
    }
}

const mapStateToProps = state => ({
    pubs: state.scorecard.pubs || []
});

const mapDispatchToProps = dispatch => ({
    addPub: (e) => {
        e.preventDefault();
        const pub = document.getElementById('pub').value;
        const drink = document.getElementById('drink').value;
        const par = document.getElementById('par').value;

        dispatch({ type: 'ADD_PUB', pub, drink, par });

        document.getElementById('pub').value = '';
        document.getElementById('drink').value = '';
        document.getElementById('par').value = '';
    },
    deletePub: index => {
        dispatch({ type: 'DELETE_PUB', index });
    },
    editPub: (e, index) => {
        dispatch({ type: 'EDIT_PUB_NAME', index, pub: e.target.value })
    },
    editDrink: (e, index) => {
        dispatch({ type: 'EDIT_PUB_DRINK', index, drink: e.target.value })
    },
    editPar: (e, index) => {
        dispatch({ type: 'EDIT_PUB_PAR', index, par: e.target.value })
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Pubs);