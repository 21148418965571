import React from "react";
import styled from "styled-components";
import Rules from "./Rules";
import Pubs from "./Pubs";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createDefinition } from "../pdf/creator";
import axios from "axios";
import download from "downloadjs";
import { Helmet } from "react-helmet";

const ScorecardStyles = styled.div`
  .details {
    padding: 20px;
    padding-bottom: 0;

    input {
      width: 100%;
    }
  }

  .generate {
    margin: 20px;

    .error,
    .alert {
      padding: 15px;
      margin-bottom: 20px;
      border-radius: 20px;
      font-size: 1.2rem;
      text-align: center;
    }

    .error {
      color: #8a3b3b;
      background-color: #fce3e3;
      border: 1px solid #8a3b3b;
    }

    .alert {
      background-color: #dff0d8;
      border-color: #d0e9c6;
      color: #3c763d;
      border: 1px solid #3c763d;
    }

    button {
      width: 100%;
    }
  }

  .hidden {
    display: none;
  }
`;

class Scorecard extends React.Component {
  constructor() {
    super();
    this.state = {
      showError: false,
      showAlert: false,
      showPdfError: false,
    };
  }

  generateScorecard = (e) => {
    e.preventDefault();

    const { pubs, rules, name } = this.props;

    if (rules.length === 0 || pubs.length === 0 || name === "") {
      this.setState({ showError: true, showAlert: false });
    } else {
      const definition = createDefinition(pubs, rules, name);

      axios
        .post(
          "https://api-pubgolfscorecard-co-uk.onrender.com/v1/generate-pdf",
          definition
        )
        .then((response) => {
          // handle success
          const content = response.headers["content-type"];
          download(response.data, "scorecard.pdf", content);
          this.setState({ showError: false, showAlert: true });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            showError: false,
            showAlert: false,
            showPdfError: true,
          });
        });
    }
  };

  render() {
    const { showAlert, showError, showPdfError } = this.state;
    const { addName, name } = this.props;

    return (
      <ScorecardStyles>
        <Helmet>
          <title>
            Pub Golf Scorecard - Online Pub Golf Scorecard Generator, Create
            your pub golf scorecard for FREE, Scorecard Templates
          </title>
        </Helmet>
        <div className="details">
          <h2>1. Choose your pub crawl name</h2>
          <label htmlFor="name" className="visuallyhidden">
            Pub crawl name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            required
            placeholder="Enter a name for your pub crawl..."
            value={name}
            onChange={(e) => addName(e)}
          />
        </div>
        <Pubs />
        <Rules />
        <h2>4. Generate your scorecard</h2>
        <div className="generate">
          {showError ? (
            <div className="error">
              Please enter at least 1 pub, 1 rule and a crawl name
            </div>
          ) : (
            <React.Fragment />
          )}
          {showAlert ? (
            <div className="alert">
              Your PDF will be downloaded now to your device, please check this
              isn't blocked by your browser
            </div>
          ) : (
            <React.Fragment />
          )}
          {showPdfError ? (
            <div className="error">
              There was a problem generating your PDF, please try again
            </div>
          ) : (
            <React.Fragment />
          )}
          <button onClick={(e) => this.generateScorecard(e)}>
            Generate Scorecard PDF
          </button>
        </div>
      </ScorecardStyles>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.scorecard,
});

const mapDispatchToProps = (dispatch) => ({
  addName: (e) => dispatch({ type: "ADD_NAME", name: e.target.value }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Scorecard)
);
