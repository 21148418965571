import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const StyledWhatToWear = styled.section`
    margin: 20px;

    .outfits {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;

        .outfit {
            width: 300px;

            h4 {
                text-align: center;
                color: ${props => props.theme.colors.pink}
            }

            img {
                display: block;
                margin: 0 auto;
            }
        }
    }
`;

const WhatToWear = () => (
    <StyledWhatToWear>
        <Helmet>
            <title>What to wear for pub golf, pub golf outfits - Pub Golf Scorecard</title>
        </Helmet>
        <h2>What to wear for pub golf</h2>
        <p>Generally the game of Pub Golf is played whilst everyone is dressed up in the correct attire, for example you can go as far as you want.. from just wearing a flat cap and taking along your best plastic 5 iron you got from Toys R Us to the whole outfit even fitted with knee high socks (not advisable to take along a real golf club, as we don't think you would end up getting in most of the pubs you wish to visit during the game!).</p>
        <h3>Outfit examples</h3>
        <p>Below are some examples of outfits you might want to wear on the big day!</p>

        <div className="outfits">
            <div className="outfit">
                <h4>Mens Outfit 1</h4>
                <img src="/static/men1.jpg" alt="Man outfit idea for pub golf" />
            </div>
            <div className="outfit">
                <h4>Mens Outfit 2</h4>
                <img src="/static/men2.jpg" alt="Another man outfit idea for pub golf" />
            </div>
            <div className="outfit">
                <h4>Womans Outfit 1</h4>
                <img src="/static/girl1.jpg" alt="Womans outfit idea for pub golf" />
            </div>
            <div className="outfit">
                <h4>Womans Outfit 2</h4>
                <img src="/static/girl2.jpg" alt="Another womans outfit idea for pub golf" />
            </div>
        </div>

        <h3>Where can i buy these items?</h3>
        <p>Your best bet would be a dress up shop specifically set up for these kind of events, an outfit will probably set you back about £10 to £20. Although you can probably just head to H&amp;M and pick up most of the things you need.. e.g. knee high socks, polo shirt, skirt, you could wear any shoes (or just buy some cheap pumps), and get a cheap visor from Sports Direct (probably only a couple of quid if you look around).</p>
    </StyledWhatToWear>
);

export default WhatToWear;