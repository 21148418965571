import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    html {
        overflow-x: hidden;
        min-height: 100%;
        position: relative;
    }

    body {
        overflow-x: hidden;
        height: 100%;
        color: #676767;
        font-family: 'Noto Sans TC', sans-serif;
        margin: 0;
        padding: 0;
        background-image: url('../static/sativa.png');
        background-repeat: repeat;
        background-color: #ebebeb;
    }

    h2 {
        color: #373737;
        width: 100%;
        margin-top: 0;
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 2px dotted #8BC34A;
        text-align: center;
        font-size: 1.4rem;

        @media ${props => props.theme.breakpoints.medium} {
            font-size: 1.5rem;
        }
    }

    h3 {
        color: #655a5e;
    }

    a, a:visited, a:hover {
        color: #006CFF;

        &:hover {
            text-decoration: none;
        }
    }

    .hidden {
        display: none;
    }

    .visuallyhidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table input, table select {
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
        border: 1px solid #8BC34A;
        font-size: 1rem;
    }

    button {
        border-radius: 20px;
        background: linear-gradient(to right,rgb(243, 117, 117),rgb(255, 119, 175));
        font-weight: bold;
        color: white;
        font-size: 1.2rem;
        padding: 15px;
        position: relative;
        border: none;

        i {
            position: absolute;
            right: 13px;
            top: 50%;
            margin-top: -10px;
        }
    }

    input, select {
        border-radius: 20px;
        padding: 15px;
        border: 2px solid #8BC34A;
        box-sizing: border-box;
        font-size: 1.2rem;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .forms {
        padding: 20px;

        .add {

        }

        .info {
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;

            @media ${props => props.theme.breakpoints.medium} {
                flex-wrap: nowrap;
            }

        }
        

        .enter-info {
            width: 100%;

            @media ${props => props.theme.breakpoints.medium} {
                width: 30%;
            }

            input, select {
                margin-top: 10px;
                width: 100%;
            }

            button {
                width: 100%;
                margin-top: 10px;
            }
        }

        .list {
            width: 100%;

            @media ${props => props.theme.breakpoints.medium} {
                width: 70%;
                padding-left: 20px;
            }
            

            table {
                width: 100%;
                margin-top: 20px;
                border-collapse: collapse;

                td, th {
                    padding: 15px;
                    border: 0;
                    margin: 0;
                }

                th {
                    color: black;
                    text-align: left;
                }

                thead {
                    background-color: #8BC34A;
                }

                tbody {
                    tr {
                        background-color: white;
                    }

                    tr:nth-child(even){
                        background-color: #efefef;
                        color: #6b6b6b;
                    }
                }

                .par-row, .par-header, .hole-header, .hole-row, .small-row {
                    width: 50px;
                    text-align: center;
                }

                .small-row {
                    i {
                        font-size: 1.5rem;
                        margin-right: 10px;
                        cursor: pointer;

                        &:last-child {
                            margin-right: 0;
                        }

                        &.delete {
                            color: #f74949;
                        }
                    }
                }
            }
        }
    }
`;

export default GlobalStyles;