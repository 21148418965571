import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

const FooterStyles = styled.footer`
    border-top: 5px solid #89c441;
    margin-top: 20px;
    padding: 20px;
    text-align: center;
    background-color: #2d2d2d;
    color: white;
    background: url('../static/pink-dust.jpg');
    background-repeat: repeat;
    background-color: #363636;

    a, a:visited, a:hover {
        color: #8BC34A;
    }
`;

class Footer extends React.Component {
    render() {
        const { pathname } = this.props.location;

        if (pathname.includes('generate-pdf'))
            return <React.Fragment />;

        return (
            <FooterStyles>
                <div>&copy; Pub Golf Scorecard || <a href="mailto:info@pubgolfscorecard.co.uk">Contact</a> || <a href="https://www.drinkaware.co.uk" target="_blank" rel="noopener noreferrer">Drink Aware</a>
                <p>
                    <b>Disclaimer: </b> Please note that this website is a novelty website and the drinking game of Pub Golf is supposed to be a fun game to be enjoyed, so please don't drink yourself stupid and <a href="https://www.knowyourlimits.info/AboutAlcohol.aspx" target="_blank" rel="noopener noreferrer">know your limits!</a>
                </p>
                </div>
            </FooterStyles>
        )
    }
}

export default withRouter(Footer);