import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

const StyledRules = styled.section`
    .no-items {
        text-align: center;
        margin-top: 20px;
        font-size: 1.4rem;
        color: ${props => props.theme.colors.pink};

        .mobile-message {
            display: block;
            font-size: 1rem;
            background-color: white;
            padding: 10px;
            border-radius: 10px;

            @media ${props => props.theme.breakpoints.medium} {
                display: none;
            }
        }

        .desktop-message {
            display: none;
            background-color: white;
            padding: 10px;
            border-radius: 10px;

            @media ${props => props.theme.breakpoints.medium} {
                display: block;
            }
        }
    }

    .hidden {
        display: none;
    }

    .edit-rule {
        cursor: pointer;
    }

    .examples {
        margin-left: 20px;
        margin-right: 20px;
        padding: 10px;
        background-color: white;
        border-radius: 10px;
        margin-bottom: -20px;

        a {
            color: ${props => props.theme.colors.pink};
            margin-left: 10px;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        ul {
            margin-bottom: 0;
            padding-left: 30px;
            margin-top: 10px;
        }
    }
`;

class Rules extends React.Component {
    constructor() {
        super();

        this.state = {
            editIndex: null
        }
    }

    editField = (index) => {
        this.setState({ editIndex: index });
        setTimeout(function(){ document.getElementById(`rule${index}`).focus(); }, 50);
    }

    render() {
        const { rules, addRule, deleteRule, editRule, addExampleRule } = this.props;
        const { editIndex } = this.state;

        return (
            <StyledRules>
                <h2>3. Enter the rules of your course</h2>
                <div className="examples">
                    <b>Some examples include</b>
                    <ul>
                        {/* eslint-disable */}
                        <li>Drinks must be consumed within 15 minutes <a href="javascript:void(0);" onClick={() => addExampleRule('Drinks must be consumed within 15 minutes')}><i className="fas fa-plus-circle"></i> Add this rule</a></li>
                        <li>If you are sick, +10 to your score <a href="javascript:void(0);" onClick={() => addExampleRule('If you are sick, +10 to your score')}><i className="fas fa-plus-circle"></i> Add this rule</a></li>
                        <li>No phones allowed while on the course <a href="javascript:void(0);" onClick={() => addExampleRule('No phones allowed while on the course')}><i className="fas fa-plus-circle"></i> Add this rule</a></li>
                        {/* eslint-enable */}
                    </ul>
                </div>
                <div className="forms">
                <div className="info">
                    <div className="enter-info">
                        <form onSubmit={(e) => addRule(e)}>
                            <label htmlFor="rule" className="visuallyhidden">Rule</label>
                            <input type="text" id="rule" name="rule" placeholder="Enter a rule..." required />
                            <button type="submit">Add Rule<i className="fas fa-plus"></i></button>
                        </form>
                    </div>
                    <div className="list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Rule</th>
                                    <th className="small-row"></th>
                                </tr>
                            </thead>
                            <tbody>
                            {rules && rules.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div onClick={() => this.editField(index)} className={editIndex === index ? 'hidden' : 'edit-rule'}>{item.rule}</div>
                                            <input id={`rule${index}`} className={editIndex === index ? '' : 'hidden'} value={item.rule} onChange={(e) => editRule(e, index)} onBlur={() => this.setState({ editIndex: null })} />
                                        </td>
                                        <td className="small-row">
                                            <i className="far fa-trash-alt delete" onClick={() => deleteRule(index)}></i>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                            </tbody>
                        </table>
                        {
                            rules.length === 0 ? <div className="no-items">
                                <div className="desktop-message"><i className="far fa-hand-point-left"></i> <b>Start by adding a rule using the form to the left</b></div>
                                <div className="mobile-message"><i className="far fa-hand-point-up"></i> <b>Start by adding a rule using the form above</b></div>
                            </div> : <React.Fragment />
                        }
                    </div>
                    </div>
                </div>
            </StyledRules>
        );
    }
}

const mapStateToProps = state => ({
    rules: state.scorecard.rules || []
});

const mapDispatchToProps = dispatch => ({
    deleteRule: (index) => {
        dispatch({ type: 'DELETE_RULE', index });
    },
    addRule: (e) => {
        e.preventDefault();
    
        const rule = document.getElementById('rule').value;
    
        dispatch({ type: 'ADD_RULE', rule });
    
        document.getElementById('rule').value = '';
    },
    editRule: (e, index) => {
        dispatch({ type: 'EDIT_RULE', index, rule: e.target.value })
    },
    addExampleRule: (rule) => {
        dispatch({ type: 'ADD_RULE', rule });
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Rules);