import React from 'react';
import Scorecard from './Scorecard';
import HowToPlay from './HowToPlay';
import Templates from './Templates';
import WhatToWear from './WhatToWear';
import Accessories from './Accessories';

import { Switch, Route } from 'react-router-dom'

const Main = () => (
    <main>
      <Switch>
        <Route exact path='/' component={Scorecard}/>
        <Route path='/how-to-play' component={HowToPlay}/>
        <Route path='/howtoplay' component={HowToPlay}/>
        <Route path='/templates' component={Templates}/>
        <Route path='/what-to-wear' component={WhatToWear}/>
        <Route path='/whattowear' component={WhatToWear}/>
        <Route path='/accessories' component={Accessories}/>
        <Route component={Scorecard}/>
      </Switch>
    </main>
);

  export default Main;