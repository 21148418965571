import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Header from "./components/Header.jsx";
import GlobalStyles from "./components/styles/GlobalStyles.jsx";
import Main from "./components/Main.jsx";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import { Provider } from "react-redux";
import { createStore } from "redux";
import reducers from "./reducers/index";
import { composeWithDevTools } from "redux-devtools-extension";
import axios from "axios";

const blankScorecard = {
  pubs: [],
  rules: [],
  name: "",
};
const persistedState = localStorage.getItem("reduxState")
  ? JSON.parse(localStorage.getItem("reduxState"))
  : blankScorecard;
const store = createStore(
  reducers,
  { scorecard: persistedState },
  composeWithDevTools()
);

store.subscribe(() => {
  localStorage.setItem(
    "reduxState",
    JSON.stringify(store.getState().scorecard)
  );
});

const theme = {
  breakpoints: {
    small: "(min-width: 576px)",
    medium: "(min-width: 768px)",
    large: "(min-width: 992px)",
    extraLarge: "(min-width: 1200px)",
  },
  colors: {
    green: "#8BC34A",
    pink: "#f37575",
  },
};

const App = () => {
  useEffect(() => {
    axios.get("https://api-pubgolfscorecard-co-uk.onrender.com/healthcheck");
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div>
            <GlobalStyles />
            <Header />
            <Navigation />
            <Main />
            <Footer />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
