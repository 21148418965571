import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const StyledHowToPlay = styled.section`
    margin: 20px;

    img {
        max-width: 100%;
        display: block;
        margin: 0 auto;
    }
`;

const HowToPlay = () => (
    <StyledHowToPlay>
        <Helmet>
            <title>How to play pub golf - Pub Golf Scorecard</title>
        </Helmet>
        <h2>How to Play Pub Golf</h2>
        <p>You play Pub Golf by first setting out the course around the town(s) you are playing it in. This is done by picking pubs, bars or clubs and choosing a drink a player has to drink in that place. For example you may choose to play a 9 hole course and your first hole would be Pub: "The Globe", Drink "A Pint of Beer" and you repeat this process across as many pubs that you want to play it in varying the drink from pub to pub.</p>

        <p>You generally score by the amount of drinks/swigs/gulps a player takes from the drink per hole, for example if a player downs the drink, then this will be classed as a hole in one!</p>

        <p>You can set a par for the hole, this is how many drinks a player should take out of a drink before it is finished on that hole e.g. if you have a shot on a hole, this normally would be a par 1.</p>

        <p>You can also add in optional rules to make the game more interesting, for example you could add in a rule that states "if a player drinks the wrong drink in a pub then they get +4 added onto their score for that hole", you can make as many rules as you want to make the game to your taste!</p>

        <img src='/static/guinness.jpg' alt="A pint of guinness" align="center" />
    </StyledHowToPlay>
);

export default HowToPlay;