import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

const HeaderStyles = styled.header`
    text-align: center;
    height: 100%;
    background-image: url('../static/prism.png');
    background-repeat: repeat;
    padding: 20px;
    background-color: #363636;
    position: relative;

    h1 {    
        font-size: 2.5rem;
        color: #8BC34A;
        font-weight: bold;
        text-align: left;
        margin: 0;
        margin-bottom: 10px;
        border-bottom: 2px dotted #8BC34A;
        padding-right: 30px;
        font-size: 1.8rem;
        margin-top: -5px;

        a {
            color: #8BC34A;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        @media ${props => props.theme.breakpoints.large} {
            font-size: 4rem;
            margin-top: 0px;
            text-align: center;
        }
    }

    .tag-line {
        margin: 0px;
        color: white;
        text-align: left;
        padding: 0px;

        @media ${props => props.theme.breakpoints.large} {
            text-align: center;
            padding-left: 100px;
            padding-right: 100px;
            padding: 10px;
        }
    }

    i {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 2rem;
        color: #8BC34A;
        z-index: 10;
        cursor: pointer;

        @media ${props => props.theme.breakpoints.large} {
            font-size: 2.5rem;
        }

        &.hidden {
            display: none;
        }
    }
`;

const Header = ({ clickMenu, isMenuShown }) => (
    <HeaderStyles>
        <h1><NavLink to="/">Pub Golf Scorecard</NavLink></h1>
        <div className="tag-line">
            Pub Golf Scorecard is a simple online generator to help you create your own pub golf scorecard in just a few clicks! Just enter your pubs, drinks and rules below and we give you a simple PDF, then all you need to do is print it.
            <br /><br />
            Pub Golf is a fun bar crawl style drinking game to be enjoyed in a small or large group of your choice. It is the perfect drinking game for Stag Dos, Hen Dos, Birthdays, Leaving Dos, Celebrations…. or simply because you want to!
        </div>
        <i className={`fas fa-bars ${isMenuShown ? 'hidden' : ''}`} onClick={clickMenu}></i>
        <i className={`fas fa-times ${!isMenuShown ? 'hidden' : ''}`} onClick={clickMenu}></i>
    </HeaderStyles>
);

const mapStateToProps = state => ({
    ...state.navigation
});

const mapDispatchToProps = dispatch => ({
    clickMenu: () => dispatch({ type: 'CLICK_MENU' })
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);