import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { items } from '../data/accessories.json';

const StyledAccessories = styled.section`
    margin: 20px;

    .outfits {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;

        .outfit {
            width: 300px;

            h4 {
                text-align: center;
                color: ${props => props.theme.colors.pink}
            }

            img {
                display: block;
                margin: 0 auto;
            }
        }
    }
`;

const Accessories = () => (
    <StyledAccessories>
        <Helmet>
            <title>Accessories for Pub Golf, what to take with you - Pub Golf Scorecard</title>
        </Helmet>
        <h2>Accessories for pub golf</h2>
        <p>To make your pub golf game extra special you might want to bring along and wear some golf accessories to make your outfit even more complete. Below are some examples of accessories you can get to complete your pub golf game.</p>
        
        <div className="outfits">
         {
             items.map(item => (
                <div className="outfit">
                    <h4>{item.name}</h4>
                    <img src={`/static/${item.image}`} alt={item.alt} />
                    <p>{item.description}</p>
                </div>
             ))
         }
        </div>

        <h3>Where can i buy these items?</h3>
        <p>Your best bet would be a dress up shop specifically set up for these kind of events, an outfit will probably set you back about £10 to £20. Although you can probably just head to H&amp;M and pick up most of the things you need.. e.g. knee high socks, polo shirt, skirt, you could wear any shoes (or just buy some cheap pumps), and get a cheap visor from Sports Soccer (probably only a couple of quid if you look around).</p>
    </StyledAccessories>
);

export default Accessories;