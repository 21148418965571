import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const NavigationStyles = styled.nav`
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.9);
    color: #8BC34A;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 2;

    &.hidden {
        display: none;
    }

    .inner-menu {
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }

    ul {
        font-size: 3rem;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 20px;

            a, a:visited, a:hover {
                color: #8BC34A;
                text-decoration: none;
                font-weight: bold;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
`;

const Navigation = ({ isMenuShown, closeMenu }) => (
    <NavigationStyles className={`${!isMenuShown ? 'hidden' : ''}`}>
        <div className="inner-menu">
            <ul>
                <li><NavLink to="/" onClick={closeMenu}>Home</NavLink></li>
                <li><NavLink to="/how-to-play" onClick={closeMenu}>How to play</NavLink></li>
                <li><NavLink to="/what-to-wear" onClick={closeMenu}>What to wear</NavLink></li>
                <li><NavLink to="/accessories" onClick={closeMenu}>Accessories</NavLink></li>
                <li><NavLink to="/templates" onClick={closeMenu}>Templates</NavLink></li>
            </ul>
        </div>
    </NavigationStyles>
);

const mapStateToProps = state => ({
    ...state.navigation
});

const mapDispatchToProps = dispatch => ({
    closeMenu: () =>  dispatch({ type: 'CLICK_MENU' })
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);