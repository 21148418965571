export const createDefinition = (pubs, rules, name) => {
    const pubsTable = pubs.map((item, index) => {
        return [index + 1, item.pub, item.drink, item.par, '']
    })

    const rulesList = rules.map(item => {
        return item.rule;
    });

    const definition = {
        content: [
            {
                text: name,
                style: 'header'
            },
            {
                text: 'Player: ...........................................................................................................................................',
                style: 'player'
            },
            {
                style: 'table',
                table: {
                    widths: [30, '*', '*', 30, 40],
                    body: [
                        [{
                            text: 'Hole',
                            style: 'tableHeader'
                        },
                        {
                            text: 'Pub',
                            style: 'tableHeader'
                        },
                        {
                            text: 'Drink',
                            style: 'tableHeader'
                        },
                        {
                            text: 'Par',
                            style: 'tableHeader'
                        },{
                            text: 'Score',
                            style: 'tableHeader'
                        }],
                        ...pubsTable
                    ]
                }
            },
            {
                text: 'Rules',
                style: 'rules'
            },
            {
                ol: [
                    ...rulesList
                ]
            },
            {
                text: 'Scorecard generated by www.pubgolfscorecard.co.uk',
                style: 'footer'
            }
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                alignment: 'center'
            },
            table: {
                margin: [0, 20, 0, 20]
            },
            tableHeader: {
                fillColor: '#8BC34A',
                bold: true
            },
            player: {
                bold: true,
                margin: [0, 20, 0, 0]
            },
            rules: {
                bold: true,
                margin: [0, 0, 0, 10]
            },
            footer: {
                alignment: 'center',
                margin: [0, 20, 0, 20]
            }
        }
    }

    return definition;
}