const initialState = {
    name: '',
    pubs: [],
    rules: []
}

const reducer = (state = initialState, action) => {
    if (action.type === 'ADD_NAME') {
        return {
            ...state,
            name: action.name
        }
    }

    if (action.type === 'ADD_PUB') {
        return {
            ...state,
            pubs: [...state.pubs, { pub: action.pub, drink: action.drink, par: action.par }]
        }
    }

    if (action.type === 'ADD_RULE') {
        return {
            ...state,
            rules: [...state.rules, {rule: action.rule}]
        }
    }

    if (action.type === 'EDIT_RULE') {
        return {
            ...state,
            rules: state.rules.map((rule, i) => i === action.index 
                ? {...rule, rule: action.rule}
                : rule
            )
        }
    }

    if (action.type === 'EDIT_PUB') {
        return {
            ...state,
            pubs: state.pubs.map((pub, i) => i === action.index 
                ? {...pub, pub: action.pub, drink: action.drink, par: action.par}
                : pub
            )
        }
    }

    if (action.type === 'EDIT_PUB_NAME') {
        return {
            ...state,
            pubs: state.pubs.map((pub, i) => i === action.index 
                ? {...pub, pub: action.pub}
                : pub
            )
        }
    }

    if (action.type === 'EDIT_PUB_DRINK') {
        return {
            ...state,
            pubs: state.pubs.map((pub, i) => i === action.index 
                ? {...pub, drink: action.drink}
                : pub
            )
        }
    }

    if (action.type === 'EDIT_PUB_PAR') {
        return {
            ...state,
            pubs: state.pubs.map((pub, i) => i === action.index 
                ? {...pub, par: action.par}
                : pub
            )
        }
    }

    if (action.type === 'DELETE_PUB') {
        return {
            ...state,
            pubs: state.pubs.filter((item, index) => index !== action.index)
        }
    }

    if (action.type === 'DELETE_RULE') {
        return {
            ...state,
            rules: state.rules.filter((item, index) => index !== action.index)
        };
    }

    return state;
};

export default reducer;